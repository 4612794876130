import React from "react"

// default components
import {
  Layout,
  Landing,
  GreenBar,
  Banner,
  SectionFAQ,
  Reviews,
  Trips,
  useHomePageQuery,
  useFAQQuery,
  renderSeo,
  useWebSiteConfigQuery,
} from "@nt-websites/navigate-theme"

const FAQ = ({ data }) => {
  // extracting our custom hook
  const bottomBannerImage = useWebSiteConfigQuery()
    .contentfulWebsiteConfiguration.websiteBottomBannerImage.localFile
    .childImageSharp.fluid

  const FAQBannerImmage = useWebSiteConfigQuery().contentfulWebsiteConfiguration
    .faqBannerImage.localFile.childImageSharp.fluid

  const homeQuery = useHomePageQuery()
  const FAQData = useFAQQuery()

  return (
    <Layout>
      {renderSeo(data.contentfulSeoPageMeta)}
      <div className="hotfix--narrow-banner">
        <Landing
          imageData={FAQBannerImmage}
          titleFirst="FAQs"
          buttonFirst="expore"
          buttonFirstURL="/blog"
          description="Have questions? Find all the answers below so you can be fully prepared for the adventure of a lifetime."
          buttonStyles={["white", "white"]}
          optMargin="u-margin-top-percent-10"
          variation="dest"
        />
      </div>
      <GreenBar />
      <SectionFAQ FAQData={FAQData} hideVideos />
      <Banner {...data.contentfulSeoPageMeta.bottomBanner} />

      <Reviews />
      <Trips data={homeQuery[0].node.popularTours} headerText="Popular Tours" />
    </Layout>
  )
}
/**
 * We should use seo identifier variables from const PAGE_SEO_IDENTIFIER on this query instead plain strings. . But to do so, we need to pass
 * this data as a context. See LekoArts answer in https://github.com/gatsbyjs/gatsby/issues/10023.
 */
export const query = graphql`
  query {
    contentfulSeoPageMeta(referencedPageIdentifier: { eq: "faq" }) {
      ...PageMeta
    }
  }
`

export default FAQ
